import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import '@/styles/app.scss';
import Home from '@/modules/home';
import { LoginCallback } from '@okta/okta-react';
import { RequiredAuth } from '@/components/secure-route';
import Loading from '@/components/loading';
import Users from '@/modules/user-managment/users';
import Revoked from '@/modules/user-managment/revoked';
import Edit from '@/modules/user-managment/user';
import BusinessTeams from '@/modules/user-managment/business-teams';
import Admins from '@/modules/user-managment/admins';
import ConfigurationsList from '@/modules/configurations/all';
import Draft from '@/modules/configurations/draft';
import Categories from '@/modules/configurations/categories';
import EditConfiguration from '@/modules/configurations/configuration';
import { AVAILABLE_ROLES } from '@/types';
import { RootState } from '@/store';
import { getUser } from '@/store/usersSlice';
import ConfigurationsListBusinessUser from '@/modules/configurations/business-team';
import EditConfigurationBusinessUser from '@/modules/configurations/business-team/edit';
import Uploads from '@/modules/uploads';
import ViewDataConfigurationBusinessUser from '@/modules/configurations/business-team/view-data';
import UploadHistoryList from '@/modules/configurations/configuration/upload-history-list';
import ActivityLogs from '@/modules/logs';
import EditConfigurationAdmin from '@/modules/configurations/admin/edit';
import ViewDataConfigurationAdmin from '@/modules/configurations/admin/view-data';

const AppRoutes: React.FC = () => {
    const user = useSelector((state: RootState) => getUser(state));

    const getEditConfigurationsComponent = (): JSX.Element => {
        switch (user.role) {
            case AVAILABLE_ROLES.ROLE_PLATFORM_ADMIN:
            case AVAILABLE_ROLES.ROLE_ADMIN:
                return <EditConfiguration />;
            case AVAILABLE_ROLES.ROLE_USER:
                return <EditConfigurationBusinessUser />;
            default:
                return <></>;
        }
    };

    const getConfigurationsComponent = (): JSX.Element => {
        switch (user.role) {
            case AVAILABLE_ROLES.ROLE_PLATFORM_ADMIN:
            case AVAILABLE_ROLES.ROLE_ADMIN:
                return <ConfigurationsList />;
            case AVAILABLE_ROLES.ROLE_USER:
                return <ConfigurationsListBusinessUser />;
            default:
                return <></>;
        }
    };

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/logout" />
            <Route path="login/openid-redirect-uri" element={<LoginCallback loadingElement={<Loading />} />} />
            {user.role !== AVAILABLE_ROLES.ROLE_USER && (
                <>
                    <Route path="/users" element={<RequiredAuth />}>
                        <Route path="" element={<Users />} />
                    </Route>
                    <Route path="/admins" element={<RequiredAuth />}>
                        <Route path="" element={<Admins />} />
                    </Route>
                    <Route path="/business-teams" element={<RequiredAuth />}>
                        <Route path="" element={<BusinessTeams />} />
                    </Route>
                    <Route path="/revoked" element={<RequiredAuth />}>
                        <Route path="" element={<Revoked />} />
                    </Route>
                    <Route path="/user/create" element={<RequiredAuth />}>
                        <Route path="" element={<Edit />} />
                    </Route>
                    <Route path="/user/:id" element={<RequiredAuth />}>
                        <Route path="" element={<Edit />} />
                    </Route>
                    <Route path="/configurations/draft" element={<RequiredAuth />}>
                        <Route path="" element={<Draft />} />
                    </Route>
                    <Route path="/configurations/categories" element={<RequiredAuth />}>
                        <Route path="" element={<Categories />} />
                    </Route>
                    <Route path="/configurations/create" element={<RequiredAuth />}>
                        <Route path="" element={<EditConfiguration />} />
                    </Route>
                    <Route path="/configurations/draft/:id" element={<RequiredAuth />}>
                        <Route path="" element={<EditConfiguration />} />
                    </Route>
                    <Route path="/configurations/uploads" element={<RequiredAuth />}>
                        <Route
                            path=""
                            element={
                                <div style={{ padding: '25px' }}>
                                    <UploadHistoryList withoutConfiguration />
                                </div>
                            }
                        />
                    </Route>
                    <Route path="/logs" element={<RequiredAuth />}>
                        <Route path="" element={<ActivityLogs />} />
                    </Route>
                </>
            )}
            <Route path="/configuration/:id" element={<RequiredAuth />}>
                <Route path="" element={getEditConfigurationsComponent()} />
            </Route>
            <Route path="/configuration/:id/my-uploads" element={<RequiredAuth />}>
                <Route path="" element={<EditConfigurationAdmin />} />
            </Route>
            <Route path="/configurations" element={<RequiredAuth />}>
                <Route path="" element={getConfigurationsComponent()} />
            </Route>
            <Route path="/configuration/:id/view-data/:historyId" element={<RequiredAuth />}>
                <Route path="" element={<ViewDataConfigurationBusinessUser />} />
            </Route>
            <Route path="/configuration/:id/my-uploads/view-data/:historyId" element={<RequiredAuth />}>
                <Route path="" element={<ViewDataConfigurationAdmin />} />
            </Route>
            <Route path="/uploads" element={<RequiredAuth />}>
                <Route path="" element={<Uploads />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default AppRoutes;
