import { makeStyles } from '@mui/styles';
import { FormControl, InputBase, InputLabel, CircularProgress, InputAdornment } from '@mui/material';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { InputBaseProps } from '@mui/material/InputBase';
import { InputProps as StandardInputProps } from '@mui/material/Input';

interface StyleProps {
    borderColor: string;
    fontColor: string;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        position: 'relative',
        display: 'flex',
    },
    inputLabel: {
        color: '#1A1D20',
        fontSize: '12px',
        transform: 'translate(0, 5px)',
    },
    inputBaseRoot: (props: StyleProps): React.CSSProperties => ({
        borderRadius: 0,
        border: '1px solid',
        borderColor: props.readonly ? 'transparent' : props.borderColor,
        fontSize: 16,
        '&:focus, &:hover': {
            borderColor: props.readonly ? 'transparent' : props.borderColor,
            boxShadow: `${props.readonly ? 'transparent 0 0 0 1px' : `${props.borderColor} 0 0 0 1px`}`,
        },
        'label + &': {
            marginTop: theme.spacing(3),
        },
        position: 'relative',
        width: '100%',
        '& .MuiInputAdornment-root': {
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            paddingRight: '10px',
            pointerEvents: 'none',
        },
    }),
    inputBaseInput: (props: StyleProps): React.CSSProperties => ({
        padding: props.readonly ? '0' : `${props.type || !props.status ? '10px 8px 10px 8px' : '10px 40px 10px 8px'}`,
        multiline: {
            padding: '10px 8px',
        },
    }),
    helperText: (props: StyleProps): React.CSSProperties => ({
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '16px',
        color: props.fontColor,
    }),
}));

interface CustomTextFieldProps {
    label?: string;
    status?: 'loading' | 'success' | 'error' | null;
    helperText?: string;
    required?: boolean;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    name?: string;
    disabled?: boolean;
    multiline?: boolean;
    rows?: number;
    inputProps?: InputBaseProps['inputProps'];
    InputProps?: StandardInputProps;
    type?: string;
    readonly?: boolean;
    fullWidth?: boolean;
    style?: React.CSSProperties;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
    label,
    status,
    helperText,
    required,
    value,
    onChange,
    name,
    disabled,
    multiline,
    rows,
    inputProps,
    InputProps,
    type,
    readonly = false,
    fullWidth = false,
    style,
}) => {
    const borderColor =
        status === 'loading' ? 'blue' : status === 'success' ? 'green' : status === 'error' ? '#D62623' : '#54616D';

    const fontColor = status === 'error' ? '#D62623' : '#54616D';

    const classes = useStyles({ borderColor, fontColor, type, readonly, status });

    let endAdornment: React.ReactNode;
    switch (status) {
        case 'loading':
            endAdornment = <CircularProgress size={20} />;
            break;
        case 'success':
            endAdornment = <CheckCircleIcon style={{ color: 'green' }} />;
            break;
        case 'error':
            endAdornment = <HighlightOffIcon style={{ color: 'red' }} />;
            break;
        default:
            endAdornment = null;
    }

    return (
        <FormControl variant="standard" className={classes.formControl} fullWidth={fullWidth} style={style}>
            {label && (
                <InputLabel className={classes.inputLabel} shrink htmlFor="bootstrap-input">
                    {label}
                    {required && ' *'}
                </InputLabel>
            )}
            <InputBase
                id="bootstrap-input"
                type={type}
                classes={{
                    root: classes.inputBaseRoot,
                    input: `${classes.inputBaseInput} ${multiline ? classes.multiline : ''}`,
                }}
                endAdornment={endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>}
                value={value}
                onChange={onChange}
                name={name}
                disabled={disabled}
                multiline={multiline ? multiline : false}
                rows={multiline ? rows : 1}
                inputProps={inputProps}
                {...InputProps}
                autoComplete="off"
            />
            {helperText && <p className={classes.helperText}>{helperText}</p>}
        </FormControl>
    );
};

export default CustomTextField;
