import { useApiClient } from '@/api-client/okta-api-client-controller';
import { PaginatedResponse } from '@/types';

interface UploadHistoryParams {
    status?: 'pending' | 'in_process' | 'error' | 'processing_error' | 'scheduled' | 'done' | 'sending_fail' | 'sent';
    userKeyword?: string;
    userId?: number;
    dateFrom?: string;
    dateTo?: string;
    teamIds?: number[];
    page?: number;
    perPage?: number;
    sortBy?:
        | 'date'
        | 'status'
        | 'version'
        | 'correctionsNumber'
        | 'retriesNumber'
        | 'documentName'
        | 'configurationName';
    sortDir?: 'asc' | 'desc';
}

interface UploadHistoryRequest {
    fileUuid: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useBusinessUserUploadHistoryApi = () => {
    const { apiClient } = useApiClient();

    const buildQueryString = (params: Record<string, unknown>): string => {
        const filteredParams = Object.entries(params)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, value]) => value !== undefined && value !== '')
            .reduce((acc, [key, value]) => {
                if (Array.isArray(value)) {
                    return {
                        ...acc,
                        [key]: value.map((v) => encodeURIComponent(v)).join('&' + encodeURIComponent(key) + '='),
                    };
                }
                return { ...acc, [key]: encodeURIComponent(value.toString()) };
            }, {});

        return Object.entries(filteredParams)
            .map(([key, value]) => `${encodeURIComponent(key)}=${value}`)
            .join('&');
    };

    const createUploadHistory = async (
        configurationId: number,
        requestBody: UploadHistoryRequest,
    ): Promise<UploadHistory> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<UploadHistory>(
            `/api/v1/business-user/configuration/${configurationId}/upload-history/create`,
            { method: 'POST', body: JSON.stringify(requestBody), headers: { 'Content-Type': 'application/json' } },
            'createUploadHistory',
        );
    };

    const getUploadHistoryListById = async (
        configurationId: number,
        params: UploadHistoryParams,
    ): Promise<PaginatedResponse<UploadHistory>> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<PaginatedResponse<UploadHistory>>(
            `/api/v1/business-user/configuration/${configurationId}/upload-history/list?${queryString}`,
            { method: 'GET' },
            'getUploadHistoryList',
        );
    };

    const getUploadHistoryList = async (params: UploadHistoryParams): Promise<PaginatedResponse<UploadHistory>> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<PaginatedResponse<UploadHistory>>(
            `/api/v1/business-user/upload-history/list?${queryString}`,
            { method: 'GET' },
            'getUploadHistoryList',
        );
    };

    const getUploadHistory = async (configurationId: number, uploadHistoryId: number): Promise<UploadHistory> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<UploadHistory>(
            `/api/v1/business-user/configuration/${configurationId}/upload-history/${uploadHistoryId}`,
            { method: 'GET' },
            'getUploadHistory',
        );
    };

    const getUploadHistoryManuallySend = async (uploadHistoryId: number): Promise<UploadHistory> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<UploadHistory>(
            `/api/v1/business-user/upload-history/${uploadHistoryId}/manually-send`,
            { method: 'GET' },
            'getUploadHistoryManuallySend',
        );
    };

    const getUploadHistoryDataList = async (
        uploadHistoryId: number,
        cursor?: number,
    ): Promise<UploadHistoryDataList> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        const url = cursor
            ? `/api/v1/business-user/upload-history/${uploadHistoryId}/data?cursor=${cursor}`
            : `/api/v1/business-user/upload-history/${uploadHistoryId}/data`;
        return apiClient.fetch<UploadHistoryDataList>(url, { method: 'GET' }, 'getUploadHistoryDataList');
    };

    const updateUploadHistoryData = async (
        uploadHistoryId: number,
        cellId: number,
        value: string,
    ): Promise<UploadHistory> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<UploadHistory>(
            `/api/v1/business-user/upload-history/${uploadHistoryId}/data/${cellId}/update`,
            { method: 'PUT', body: JSON.stringify({ value }), headers: { 'Content-Type': 'application/json' } },
            'updateUploadHistoryData',
        );
    };

    return {
        createUploadHistory,
        getUploadHistoryListById,
        getUploadHistoryList,
        getUploadHistory,
        getUploadHistoryDataList,
        getUploadHistoryManuallySend,
        updateUploadHistoryData,
    };
};
