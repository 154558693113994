import React, { createContext, useContext, useEffect, useState, ReactNode, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import ApiClient from '@/api-client/api-client';

interface ApiClientContextType {
    apiClient: ApiClient | null;
    apiLoading: boolean;
}

const ApiClientContext = createContext<ApiClientContextType | null>(null);

interface ApiClientProviderProps {
    children: ReactNode;
}

export const ApiClientProvider: React.FC<ApiClientProviderProps> = ({ children }) => {
    const { oktaAuth } = useOktaAuth();
    const [apiClient, setApiClient] = useState<ApiClient | null>(null);
    const [apiLoading, setApiLoading] = useState(true);
    const hasFetchedRef = useRef(false);

    useEffect(() => {
        if (hasFetchedRef.current) {
            return;
        }

        const initClient = async (): Promise<void> => {
            try {
                const client = new ApiClient(oktaAuth, '/backend' as string);
                setApiClient(client);
            } catch (error) {
                console.error('Error initializing ApiClient:', error);
            } finally {
                setApiLoading(false);
            }
        };

        initClient();
        hasFetchedRef.current = true;
    }, [oktaAuth]);

    return <ApiClientContext.Provider value={{ apiClient, apiLoading }}>{children}</ApiClientContext.Provider>;
};

export const useApiClient = (): ApiClientContextType => {
    const context = useContext(ApiClientContext);
    if (!context) {
        throw new Error('useApiClient must be used within an ApiClientProvider');
    }
    return context;
};
