import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getUser } from '@/store/usersSlice';
import CustomButton from '@/components/custom-button';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Button, Paper, Grid } from '@mui/material';
import { useUploadHistoryApi } from '@/api-client/upload-history-api';
import { Link } from 'react-router-dom';
import { AVAILABLE_ROLES } from '@/types';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles({
    success: {
        color: 'green',
    },
    failed: {
        color: 'red',
    },
    inProcess: {
        color: 'orange',
    },
});

const Home: React.FC = () => {
    const { oktaAuth } = useOktaAuth();
    const user = useSelector((state: RootState) => getUser(state));
    const { getUploadHistoryDashboardList } = useUploadHistoryApi();
    const [configurations, setConfigurations] = useState<UploadHistory[]>([]);
    const [filteredData, setFilteredData] = useState<UploadHistory[]>([]);
    const [statusFilter, setStatusFilter] = useState<string>('all');
    // const [currentPage, setCurrentPage] = useState<number>(1);
    // const [totalPages, setTotalPages] = useState<number>(1);
    const classes = useStyles();

    const login = (): void => {
        oktaAuth.signInWithRedirect();
    };

    const getStatusClass = (status: string): string => {
        switch (status) {
            case 'done':
            case 'sent':
                return classes.success;
            case 'error':
            case 'processing_error':
            case 'sending_fail':
                return classes.failed;
            case 'in_process':
                return classes.inProcess;
            default:
                return '';
        }
    };

    useEffect(() => {
        const fetchConfigurations = async (): Promise<void> => {
            try {
                const response = await getUploadHistoryDashboardList({
                    page: 1,
                    perPage: 30,
                    status: 'all',
                });
                const uniqueConfigurations = Array.from(
                    new Map(response.items.map((item) => [item.configurationResource.id, item])).values(),
                );
                setConfigurations(uniqueConfigurations);
            } catch (error) {
                console.error('Failed to fetch configurations:', error);
            }
        };

        const fetchFilteredData = async (): Promise<void> => {
            try {
                const response = await getUploadHistoryDashboardList({
                    // page: currentPage,
                    page: 1,
                    perPage: 10,
                    status: statusFilter,
                });
                setFilteredData(response.items);
                // setTotalPages(Math.ceil(response.totalCount / 10));
            } catch (error) {
                console.error('Failed to fetch filtered data:', error);
            }
        };

        user.role && fetchConfigurations();
        user.role && fetchFilteredData();
    }, [user.role, statusFilter]);
    // }, [user.role, statusFilter, currentPage]);

    const handleStatusFilter = (status: string): void => {
        setStatusFilter(status);
        // setCurrentPage(1); // Reset to the first page when the filter changes
    };

    // const handlePageChange = (newPage: number): void => {
    //   setCurrentPage(newPage);
    // };

    return (
        <div style={{ padding: '25px' }}>
            <h1>Welcome {user.firstName}</h1>
            {!user.email && <CustomButton onClick={login} label="Sign In" />}
            {user.email && (
                <Grid container spacing={3}>
                    <Grid item lg={5}>
                        <Paper
                            style={{ padding: '25px', height: '100%', borderRadius: '40px' }}
                            sx={{ marginTop: '32px' }}
                        >
                            <Typography variant="h6">Last Configurations Used</Typography>
                            <div style={{ overflowY: 'auto' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Configuration Name</TableCell>
                                            <TableCell>Link to Configuration</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {configurations.length ? (
                                            configurations.slice(0, 10).map((config) => (
                                                <TableRow key={config.id}>
                                                    <TableCell>{config.configurationResource.displayName}</TableCell>
                                                    <TableCell>
                                                        <Link to={`/configuration/${config.configurationResource.id}`}>
                                                            View Configuration
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={2}>N/A</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item lg={7}>
                        <Paper
                            style={{ padding: '25px', height: '100%', borderRadius: '40px' }}
                            sx={{ marginTop: '32px' }}
                        >
                            <Grid container spacing={3}>
                                <Grid item lg={6}>
                                    <Typography variant="h6">Error correction status</Typography>
                                </Grid>
                                <Grid item lg={6} style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                                    <Box sx={{ display: 'flex', gap: '6px' }}>
                                        <Button
                                            style={{ height: '24px', boxSizing: 'border-box' }}
                                            variant={statusFilter === 'all' ? 'contained' : 'outlined'}
                                            size="small"
                                            color="primary"
                                            onClick={() => handleStatusFilter('all')}
                                        >
                                            All
                                        </Button>
                                        <Button
                                            style={{ height: '24px', boxSizing: 'border-box' }}
                                            variant={statusFilter === 'success' ? 'contained' : 'outlined'}
                                            size="small"
                                            color="success"
                                            onClick={() => handleStatusFilter('success')}
                                        >
                                            Success
                                        </Button>
                                        <Button
                                            style={{ height: '24px', boxSizing: 'border-box' }}
                                            variant={statusFilter === 'error' ? 'contained' : 'outlined'}
                                            size="small"
                                            color="error"
                                            onClick={() => handleStatusFilter('error')}
                                        >
                                            Error
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <div style={{ overflowY: 'auto' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>File Name</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Errors Quantity</TableCell>
                                            <TableCell>Link to Configuration</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredData.length ? (
                                            filteredData.map((item) => (
                                                <TableRow key={item.id}>
                                                    <TableCell>
                                                        <Tooltip arrow title={item.fileResource.originalName}>
                                                            <span
                                                                style={{
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                    maxWidth: '150px',
                                                                    display: 'inline-block',
                                                                }}
                                                            >
                                                                {item.fileResource.originalName}
                                                            </span>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className={getStatusClass(item.status || '')}>
                                                            {item.status}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>{item.errorsNumber}</TableCell>
                                                    <TableCell>
                                                        <Link
                                                            to={
                                                                user.role !== AVAILABLE_ROLES.ROLE_USER
                                                                    ? `/configuration/${item.configurationResource.id}/my-uploads/view-data/${item.id}`
                                                                    : `/configuration/${item.configurationResource.id}/view-data/${item.id}`
                                                            }
                                                        >
                                                            View Upload
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={4}>N/A</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <Link
                                    to={
                                        user.role !== AVAILABLE_ROLES.ROLE_USER ? `/configurations/uploads` : `/uploads`
                                    }
                                    style={{ textAlign: 'right', display: 'block' }}
                                >
                                    My Uploads
                                </Link>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default Home;
