import React, { useEffect, useState } from 'react';
import { getAdminsFromState, getUser, setAdminsList } from '@/store/usersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import { useUsersApi } from '@/api-client/users-api';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    CircularProgress,
} from '@mui/material';
import { AVAILABLE_ROLES } from '@/types';
import { Link } from 'react-router-dom';
import CustomButton from '@/components/custom-button';
import { useSnackbar } from 'notistack';

const Admins: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { getUserInfo, getUsersList, getAdminsList } = useUsersApi();
    const dispatch: AppDispatch = useDispatch();
    const usersList = useSelector((state: RootState) => getAdminsFromState(state));
    const { revokeUser } = useUsersApi();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const userFromState = useSelector((state: RootState) => getUser(state));
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchUsersList = async (): Promise<void> => {
        setLoading(true);
        try {
            const list = await getAdminsList({});
            dispatch(setAdminsList(list));
        } catch (error) {
            console.error('Error fetching user list:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsersList();
    }, []);

    const fetchRevokeUser = async (userId: number): Promise<void> => {
        try {
            await revokeUser(userId);
            enqueueSnackbar(`User revoked successfully`, {
                variant: 'success',
                preventDuplicate: true,
            });
            fetchUsersList();
        } catch (error) {
            enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            console.error('Error restoring user:', error);
        }
    };

    const canEditUser = (currentUserRole: string, targetUserRole: string): boolean => {
        if (currentUserRole === AVAILABLE_ROLES.ROLE_PLATFORM_ADMIN) {
            return (
                targetUserRole === AVAILABLE_ROLES.ROLE_ADMIN || targetUserRole === AVAILABLE_ROLES.ROLE_PLATFORM_ADMIN
            );
        }

        if (currentUserRole === AVAILABLE_ROLES.ROLE_ADMIN) {
            return targetUserRole === AVAILABLE_ROLES.ROLE_ADMIN;
        }

        return false;
    };

    return (
        <div style={{ padding: '25px' }}>
            <Paper style={{ padding: '25px' }}>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '25px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ border: '1px solid #030F2B' }}>ID</TableCell>
                                        <TableCell style={{ border: '1px solid #030F2B' }}>Email</TableCell>
                                        <TableCell style={{ border: '1px solid #030F2B' }}>First Name</TableCell>
                                        <TableCell style={{ border: '1px solid #030F2B' }}>Last Name</TableCell>
                                        <TableCell style={{ border: '1px solid #030F2B' }}>Role</TableCell>
                                        <TableCell style={{ border: '1px solid #030F2B' }}>Business Team</TableCell>
                                        {userFromState.role !== AVAILABLE_ROLES.ROLE_USER ? (
                                            <TableCell style={{ border: '1px solid #030F2B' }}>Actions</TableCell>
                                        ) : (
                                            ''
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {usersList.items.map((user) => (
                                        <TableRow key={user.id}>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>{user.id}</TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>{user.email}</TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                {user.firstName}
                                            </TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                {user.lastName}
                                            </TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>{user.role}</TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                {user.businessTeams.map((team) => (
                                                    <div key={team.id + team.name}>{team.name}</div>
                                                ))}
                                            </TableCell>
                                            {userFromState.role !== AVAILABLE_ROLES.ROLE_USER ? (
                                                <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                    <div style={{ display: 'flex' }}>
                                                        {canEditUser(userFromState.role, user.role) && (
                                                            <CustomButton
                                                                label="Revoke"
                                                                onClick={() => fetchRevokeUser(user.id)}
                                                            />
                                                        )}
                                                        {canEditUser(userFromState.role, user.role) && (
                                                            <Link to={`/user/${user.id}`}>
                                                                <CustomButton label="Edit" />
                                                            </Link>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            ) : (
                                                ''
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={usersList.totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </Paper>
        </div>
    );
};

export default Admins;
