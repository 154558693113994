import React, { useCallback, useEffect, useState } from 'react';
import {
    Paper,
    CircularProgress,
    Typography,
    Button,
    InputAdornment,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
} from '@mui/material';
import { useParams, useLocation, Link } from 'react-router-dom';
import { ConfigurationResponse } from '@/types';
import { AppDispatch } from '@/store';
import { useDispatch, useSelector } from 'react-redux';
import {
    getConfigurationsCurrentConfiguration,
    getDefaultCurrentConfiguration,
    setConfigurationFileTemplate,
    setConfigurationSchemaResource,
    setCurrentConfiguration,
    setCurrentConfigurationDetails,
    setCurrentConfigurationErrorsNumber,
    setCurrentConfigurationId,
    setCurrentConfigurationSendingDataToBQ,
    setCurrentConfigurationStatus,
    setCurrentConfigurationValidationErrors,
    setCurrentConfigurationVersionId,
} from '@/store/configurationsSlice';
import { useBusinessUserUploadHistoryApi } from '@/api-client/business-user-upload-history-api';
import { useBusinessUserConfigurationApi } from '@/api-client/business-user-configuration-api';
import debounce from 'lodash/debounce';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSnackbar } from 'notistack';
import CustomTextField from '@/components/custom-text-field';
import CustomButton from '@/components/custom-button';
import Dialog from '@mui/material/Dialog';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ViewDataConfigurationBusinessUser: React.FC = () => {
    const [configurationId, setConfigurationId] = useState(null);
    const [currentHistoryId, setCurrentHistoryId] = useState(null);
    const [dataList, setDataList] = useState(null);
    const [cursor, setCursor] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const { id, historyId } = useParams<{ id: string }>();
    const location = useLocation();
    const dispatch: AppDispatch = useDispatch();
    const configuration = useSelector(getConfigurationsCurrentConfiguration);
    const [loading, setLoading] = useState(false);
    const { getUploadHistory, getUploadHistoryDataList, updateUploadHistoryData, getUploadHistoryManuallySend } =
        useBusinessUserUploadHistoryApi();
    const { getBusinessUserConfiguration } = useBusinessUserConfigurationApi();
    const { enqueueSnackbar } = useSnackbar();
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState<string | null>(null);

    const handleOpenModal = (content: string): void => {
        setModalContent(content);
        setOpenModal(true);
    };

    const handleCloseModal = (): void => {
        setOpenModal(false);
        setTimeout(() => {
            setModalContent(null);
        }, 100);
    };

    useEffect(() => {
        if (configuration?.id) {
            setConfigurationId(configuration?.id);
        }
    }, [configuration.id]);

    const transformToConfiguration = (configuration: unknown): ConfigurationResponse => {
        const configurationDetails = {
            technicalName: {
                value: configuration?.detailsResource?.technicalName ?? '',
                loading: false,
                success: false,
                error: false,
            },
            displayName: {
                value: configuration?.displayName ?? '',
                loading: false,
                success: false,
                error: false,
            },
            category: {
                value: configuration?.categoryResource?.name ?? '',
                loading: false,
                success: false,
                error: false,
            },
            description: {
                value: configuration?.detailsResource?.description ?? '',
                loading: false,
                success: false,
                error: false,
            },
            fileFormat: {
                value: configuration?.detailsResource?.fileType ?? '',
                loading: false,
                success: false,
                error: false,
            },
            version: {
                value: configuration?.versionResource?.version ?? '',
                loading: false,
                success: false,
                error: false,
            },
            versionComment: {
                value: configuration?.versionResource?.description ?? '',
                loading: false,
                success: false,
                error: false,
            },
            ownerEmail: configuration?.ownerEmail,
        };
        const configurationFileTemplate = configuration?.templateResource
            ? {
                  createdAt: configuration?.templateResource?.createdAt,
                  uuid: configuration?.templateResource?.uuid,
                  originalName: configuration?.templateResource?.originalName,
                  extension: configuration?.templateResource?.extension,
              }
            : null;
        const configurationSendingOptions = configuration?.sendingOptionsResource ?? null;
        dispatch(setCurrentConfigurationId(configuration?.id));
        dispatch(setCurrentConfigurationVersionId(configuration?.versionResources?.id ?? ''));
        dispatch(setCurrentConfigurationDetails(configurationDetails));
        dispatch(setConfigurationFileTemplate(configurationFileTemplate));
        dispatch(setCurrentConfigurationSendingDataToBQ(configurationSendingOptions));
    };

    useEffect(() => {
        return () => {
            dispatch(setCurrentConfiguration(getDefaultCurrentConfiguration()));
        };
    }, []);

    const debouncedUpdateUploadHistoryData = useCallback(
        debounce(async (cellId: number, value: string, errorsNumber: number, cellErrorsQty: number) => {
            try {
                const response = await updateUploadHistoryData(currentHistoryId || historyId, cellId, value);

                setDataList((prevDataList) => ({
                    ...prevDataList,
                    items: prevDataList.items.map((item) => ({
                        ...item,
                        cells: item.cells.map((cell) =>
                            cell.id === cellId
                                ? {
                                      ...cell,
                                      value: response.value,
                                      errors: response.errors,
                                      status: response.status,
                                      loading: false,
                                      success: response.status !== 'error',
                                  }
                                : cell,
                        ),
                    })),
                }));
                dispatch(setCurrentConfigurationStatus(response?.uploadHistoryStatus));
                console.log(errorsNumber);
                if (response?.status === 'valid' && errorsNumber > 0) {
                    console.log(errorsNumber);
                    //@ts-expect-error @ts-expect-error
                    dispatch(setCurrentConfigurationErrorsNumber(errorsNumber - cellErrorsQty));
                } else if (response.status === 'error') {
                    if (response?.errors?.length) {
                        //@ts-expect-error @ts-expect-error
                        response.errors.forEach((error) => {
                            enqueueSnackbar(error, { variant: 'error', preventDuplicate: true });
                        });
                    } else {
                        enqueueSnackbar('Error occurred', { variant: 'error', preventDuplicate: true });
                    }
                }
            } catch (error) {
                setDataList((prevDataList) => ({
                    ...prevDataList,
                    items: prevDataList.items.map((item) => ({
                        ...item,
                        cells: item.cells.map((cell) =>
                            cell.id === cellId ? { ...cell, loading: false, error: true } : cell,
                        ),
                    })),
                }));
                console.error('Error updating data:', error);
            }
        }, 1000),
        [],
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, cellId: number, cellErrorsQty: number): void => {
        const { value } = event.target;

        setDataList((prevDataList) => ({
            ...prevDataList,
            items: prevDataList.items.map((item) => ({
                ...item,
                cells: item.cells.map((cell) =>
                    cell.id === cellId ? { ...cell, value, loading: true, success: false, error: false } : cell,
                ),
            })),
        }));

        debouncedUpdateUploadHistoryData(cellId, value, configuration.errorsNumber, cellErrorsQty);
    };

    const fetchConfiguration = async (): Promise<void> => {
        try {
            setLoading(true);
            const configuration = await getBusinessUserConfiguration(configurationId || id);
            transformToConfiguration(configuration);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const fetchUploadHistory = async (): Promise<void> => {
        try {
            setLoading(true);
            const configuration = await getUploadHistory(configurationId || id, currentHistoryId || historyId);
            dispatch(setConfigurationSchemaResource(configuration?.schemaResource));
            dispatch(setCurrentConfigurationErrorsNumber(configuration?.errorsNumber));
            dispatch(setCurrentConfigurationStatus(configuration?.status));
            dispatch(setCurrentConfigurationValidationErrors(configuration?.validationErrors));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const fetchUploadHistoryDataList = async (cursor?: number): Promise<void> => {
        try {
            const response = await getUploadHistoryDataList(currentHistoryId || historyId, cursor);
            setDataList((prevDataList) => ({
                ...response,
                items: [...(prevDataList?.items || []), ...response.items],
            }));
            if (response.items.length > 0) {
                const lastItem = response.items[response.items.length - 1];
                setCursor(lastItem.id);
            }
            setCurrentItems(response.items);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchUploadHistoryManuallySend = async (): Promise<void> => {
        try {
            await getUploadHistoryManuallySend(currentHistoryId || historyId);
            enqueueSnackbar('The upload to BQ had been started', { variant: 'success', preventDuplicate: true });
        } catch (error) {
            enqueueSnackbar(`${error?.title}`, { variant: 'error', preventDuplicate: true });
            console.error(error);
        }
    };

    useEffect(() => {
        if (id && historyId) {
            fetchUploadHistoryDataList();
            fetchUploadHistory();
            fetchConfiguration();
            setConfigurationId(id);
            setCurrentHistoryId(historyId);
        }
    }, [id, historyId, location.pathname]);

    if (loading) {
        return (
            <Paper style={{ padding: '25px', textAlign: 'center' }}>
                <CircularProgress />
            </Paper>
        );
    }

    const renderTable = (): null | JSX.Element => {
        const schemaResource = configuration?.schemaResource;
        if (!schemaResource) return null;

        return (
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tbody>
                    <tr>
                        <td style={{ border: '1px solid #030F2B', padding: '8px', textAlign: 'left' }}>Position</td>
                        {schemaResource.columns.map((col: unknown, index: number) => (
                            <td key={index} style={{ border: '1px solid #030F2B', padding: '8px', textAlign: 'left' }}>
                                {col.position}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #030F2B', padding: '8px', textAlign: 'left' }}>Name</td>
                        {schemaResource.columns.map((col: unknown, index: number) => (
                            <td key={index} style={{ border: '1px solid #030F2B', padding: '8px', textAlign: 'left' }}>
                                {col.name}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #030F2B', padding: '8px', textAlign: 'left' }}>Type</td>
                        {schemaResource.columns.map((col: unknown, index: number) => (
                            <td key={index} style={{ border: '1px solid #030F2B', padding: '8px', textAlign: 'left' }}>
                                {col.type}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #030F2B', padding: '8px', textAlign: 'left' }}>Required</td>
                        {schemaResource.columns.map((col: unknown, index: number) => (
                            <td key={index} style={{ border: '1px solid #030F2B', padding: '8px', textAlign: 'left' }}>
                                {col.required ? 'True' : 'False'}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #030F2B', padding: '8px', textAlign: 'left' }}>Default</td>
                        {schemaResource.columns.map((col: unknown, index: number) => (
                            <td key={index} style={{ border: '1px solid #030F2B', padding: '8px', textAlign: 'left' }}>
                                {col.default}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #030F2B', padding: '8px', textAlign: 'left' }}>Nullable</td>
                        {schemaResource.columns.map((col: unknown, index: number) => (
                            <td key={index} style={{ border: '1px solid #030F2B', padding: '8px', textAlign: 'left' }}>
                                {col.nullable ? 'True' : 'False'}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #030F2B', padding: '8px', textAlign: 'left' }}>Rules</td>
                        {schemaResource.columns.map((col: unknown, index: number) => (
                            <td key={index} style={{ border: '1px solid #030F2B', padding: '8px', textAlign: 'left' }}>
                                {Array.isArray(col.rules) ? col.rules.map((rule) => rule.name).join(', ') : ''}
                            </td>
                        ))}
                    </tr>
                    {dataList?.items?.map((item) => (
                        <tr key={item.id}>
                            <td style={{ border: '1px solid #B1BACE', padding: '8px', textAlign: 'left' }}>
                                {item.number}
                            </td>
                            {item.cells.map((cell, cellIndex) => (
                                <td
                                    key={cell.id}
                                    style={{
                                        border: '1px solid #B1BACE',
                                        padding: '8px',
                                        textAlign: 'left',
                                        verticalAlign: 'bottom',
                                    }}
                                >
                                    {cell.status === 'error' && (
                                        <>
                                            <Typography variant="caption" color="error">
                                                {cell.errors.map((message, index) => (
                                                    <React.Fragment key={index}>
                                                        {index > 0 && <br />}
                                                        {message}
                                                    </React.Fragment>
                                                ))}
                                            </Typography>
                                            <IconButton
                                                style={{ padding: 0, marginLeft: '5px' }}
                                                edge="end"
                                                onClick={() =>
                                                    handleOpenModal(
                                                        Array.isArray(schemaResource.columns[cellIndex].rules)
                                                            ? schemaResource.columns[cellIndex].rules
                                                                  .map((rule) => rule.description)
                                                                  .join(' ')
                                                            : '',
                                                    )
                                                }
                                            >
                                                <InfoIcon color="error" />
                                            </IconButton>
                                        </>
                                    )}
                                    <CustomTextField
                                        key={cell.id}
                                        value={cell.value || ''}
                                        onChange={(event) => handleChange(event, cell.id, cell.errors.length)}
                                        status={cell.status === 'error' ? 'error' : ''}
                                        style={{ minWidth: '100px' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {cell.loading && <CircularProgress size={24} />}
                                                    {!cell.loading && cell.success && (
                                                        <IconButton edge="end" disableRipple>
                                                            <CheckCircleIcon color="success" />
                                                        </IconButton>
                                                    )}
                                                    {!cell.loading && cell.status === 'error' && (
                                                        <IconButton edge="end" disableRipple>
                                                            <HighlightOffIcon color="error" />
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div style={{ padding: '25px' }}>
            <Dialog open={openModal} onClose={handleCloseModal}>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <div style={{ padding: '20px', backgroundColor: 'white', margin: '20px auto', maxWidth: '400px' }}>
                    <Typography variant="body1">{modalContent}</Typography>
                </div>
            </Dialog>
            <Paper>
                {configuration && (
                    <div style={{ padding: '25px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={10}>
                                <Typography variant="h4">
                                    {configuration.details.displayName?.value || 'N/A'}
                                </Typography>
                                <Typography variant="h6" style={{ marginBottom: '10px' }}>
                                    Description: {configuration.details.description?.value || 'N/A'}
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item md={12}>
                                        <Box
                                            border={1}
                                            padding={2}
                                            borderRadius={4}
                                            borderColor="grey.400"
                                            sx={{ height: '100%', maxWidth: 'fit-content' }}
                                        >
                                            <div>
                                                <Typography variant="h6">Configuration information:</Typography>
                                            </div>
                                            <div>
                                                <Typography variant="body2">
                                                    Configuration Owner: {configuration.details.ownerEmail || 'N/A'}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Category: {configuration.details.category?.value || 'N/A'}
                                                </Typography>
                                                <Typography variant="body2">
                                                    FileFormat: {configuration.details.fileFormat?.value || 'N/A'}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Sending: {configuration?.sendingDataToBQ?.type || 'N/A'}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Version: {configuration.details.version?.value || 'N/A'}
                                                </Typography>
                                            </div>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                {configuration?.sendingDataToBQ?.type === 'manual' &&
                                    configuration?.status === 'done' && (
                                        <div>
                                            <CustomButton
                                                label="Upload"
                                                onClick={() => fetchUploadHistoryManuallySend()}
                                            />
                                        </div>
                                    )}
                                <div>
                                    <Link to="/uploads">
                                        <CustomButton label="Back to uploads list" />
                                    </Link>
                                </div>
                            </Grid>
                        </Grid>
                        <div>
                            <Typography variant="h5">Errors #: {configuration?.errorsNumber || 0}</Typography>
                            {configuration?.validationErrors?.length ? (
                                <>
                                    <Typography variant="h5">Validation Errors:</Typography>
                                    {configuration?.validationErrors.map((error, index) => (
                                        <Accordion key={index}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography style={{ fontFamily: 'sans-serif' }}>
                                                    <strong>Property Path:</strong> {error.propertyPath}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography style={{ fontFamily: 'sans-serif' }}>
                                                    <strong>Message:</strong> {error.message}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </>
                            ) : (
                                ''
                            )}
                            <Typography variant="h5">Errors report:</Typography>
                            <div style={{ overflowX: 'auto' }}>{renderTable()}</div>
                        </div>
                        {dataList && currentItems.length > 0 && currentItems.length === dataList.numItemsPerPage && (
                            <div>
                                <Button onClick={() => fetchUploadHistoryDataList(cursor)}>Load More</Button>
                            </div>
                        )}
                        {configuration?.sendingDataToBQ?.type === 'manual' && configuration?.status === 'done' && (
                            <div>
                                <CustomButton label="Upload" onClick={() => fetchUploadHistoryManuallySend()} />
                            </div>
                        )}
                        <div>
                            <Link to="/uploads">
                                <CustomButton label="Back to uploads list" />
                            </Link>
                        </div>
                    </div>
                )}
            </Paper>
        </div>
    );
};

export default ViewDataConfigurationBusinessUser;
