export interface AppConfig {
    oktaClientId: string;
    oktaClientUrl: string;
}

let appConfig: AppConfig | null = null;

export const loadConfig = async (url: string): Promise<void> => {
    try {
        // eslint-disable-next-line compat/compat
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        appConfig = await response.json();
    } catch (error) {
        console.error('Error loading config:', error);
        throw error;
    }
};

export const getConfig = (): AppConfig => {
    if (!appConfig) {
        throw new Error('Config has not been loaded');
    }
    return appConfig;
};
