import React, { useEffect, useState } from 'react';
import { Paper, CircularProgress, Typography, Box, Button, IconButton, Grid } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import { ConfigurationResponse } from '@/types';
import { AppDispatch } from '@/store';
import { useDispatch, useSelector } from 'react-redux';
import {
    getConfigurationsCurrentConfiguration,
    getDefaultCurrentConfiguration,
    setConfigurationFileTemplate,
    setCurrentConfiguration,
    setCurrentConfigurationDetails,
    setCurrentConfigurationId,
    setCurrentConfigurationSendingDataToBQ,
    setCurrentConfigurationStatus,
    setCurrentConfigurationVersionId,
} from '@/store/configurationsSlice';
import { useBusinessUserConfigurationApi } from '@/api-client/business-user-configuration-api';
import { useFilesApi } from '@/api-client/files-api';
import { useSnackbar } from 'notistack';
import UploadHistoryList from '@/modules/configurations/business-team/upload-history-list';
import { useBusinessUserUploadHistoryApi } from '@/api-client/business-user-upload-history-api';

const EditConfigurationBusinessUser: React.FC = () => {
    const [configurationId, setConfigurationId] = useState(null);
    const { getBusinessUserConfiguration } = useBusinessUserConfigurationApi();
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const dispatch: AppDispatch = useDispatch();
    const configuration = useSelector(getConfigurationsCurrentConfiguration);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [error, setError] = useState('');
    const { uploadFile, downloadFile } = useFilesApi();
    const { enqueueSnackbar } = useSnackbar();
    const { createUploadHistory } = useBusinessUserUploadHistoryApi();
    const [isDragOver, setIsDragOver] = useState(false);

    const handleFileChange = (event): void => {
        const file = event.target.files[0];
        if (file && file.name.endsWith('.csv')) {
            setSelectedFile(file);
            setError('');
        } else {
            setSelectedFile(null);
            setError(`Error: '${file.name}' is not a supported file format. Please choose a csv file and try again.`);
        }
    };

    const handleRemoveFile = (): void => {
        const fileInput = document.getElementById('file-input') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
        setSelectedFile(null);
        setError('');
    };

    const handleUploadFile = async (): Promise<void> => {
        if (selectedFile) {
            try {
                setLoading(true);
                const data = await uploadFile(selectedFile, 'data');
                try {
                    await createUploadHistory(configurationId, { fileUuid: data.uuid });
                } catch (historyError) {
                    setError('Failed to create upload history. Please try again.');
                    // Optionally log the error or handle it in some way
                    console.error('Error creating upload history:', historyError);
                }
                setLoading(false);
                handleRemoveFile();
                // You can handle successful upload here, like showing a success message or refreshing the data
            } catch (error) {
                setLoading(false);
                handleRemoveFile();
                setError('Failed to upload file. Please try again.');
            }
        }
    };

    const handleDownloadFile = async (): Promise<void> => {
        if (configuration?.fileTemplate) {
            try {
                const { uuid, extension } = configuration.fileTemplate;
                const fileData = await downloadFile(uuid, extension);

                const url = window.URL.createObjectURL(new Blob([fileData]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${configuration.fileTemplate.originalName}`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            } catch (error) {
                enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            }
        }
    };

    useEffect(() => {
        if (configuration?.id) {
            setConfigurationId(configuration?.id);
        }
    }, [configuration.id]);

    const transformToConfiguration = (configuration: unknown): ConfigurationResponse => {
        const configurationDetails = {
            technicalName: {
                value: configuration?.detailsResource?.technicalName ?? '',
                loading: false,
                success: false,
                error: false,
            },
            displayName: {
                value: configuration?.displayName ?? '',
                loading: false,
                success: false,
                error: false,
            },
            category: {
                value: configuration?.categoryResource?.name ?? '',
                loading: false,
                success: false,
                error: false,
            },
            description: {
                value: configuration?.detailsResource?.description ?? '',
                loading: false,
                success: false,
                error: false,
            },
            fileFormat: {
                value: configuration?.detailsResource?.fileType ?? '',
                loading: false,
                success: false,
                error: false,
            },
            version: {
                value: configuration?.versionResource?.version ?? '',
                loading: false,
                success: false,
                error: false,
            },
            versionComment: {
                value: configuration?.versionResource?.description ?? '',
                loading: false,
                success: false,
                error: false,
            },
            ownerEmail: configuration?.ownerEmail,
        };
        const configurationFileTemplate = configuration?.templateResource
            ? {
                  createdAt: configuration?.templateResource?.createdAt,
                  uuid: configuration?.templateResource?.uuid,
                  originalName: configuration?.templateResource?.originalName,
                  extension: configuration?.templateResource?.extension,
              }
            : null;
        const configurationSendingOptions = configuration?.sendingOptionsResource ?? null;
        dispatch(setCurrentConfigurationId(configuration?.id));
        dispatch(setCurrentConfigurationVersionId(configuration?.versionResources?.id ?? ''));
        dispatch(setCurrentConfigurationStatus(configuration?.versionResources?.status ?? ''));
        dispatch(setCurrentConfigurationDetails(configurationDetails));
        dispatch(setConfigurationFileTemplate(configurationFileTemplate));
        dispatch(setCurrentConfigurationSendingDataToBQ(configurationSendingOptions));
    };

    useEffect(() => {
        return () => {
            dispatch(setCurrentConfiguration(getDefaultCurrentConfiguration()));
        };
    }, []);

    const fetchConfiguration = async (): Promise<void> => {
        try {
            setLoading(true);
            const configuration = await getBusinessUserConfiguration(configurationId || id);
            transformToConfiguration(configuration);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            fetchConfiguration();
            setConfigurationId(id);
        }
    }, [id, location.pathname]);

    const convertTimestamp = (isoTimestamp: string | null): string => {
        if (isoTimestamp === null) {
            return '';
        }

        const date: Date = new Date(isoTimestamp);

        const day: string = String(date.getDate()).padStart(2, '0');
        const month: string = String(date.getMonth() + 1).padStart(2, '0');
        const year: number = date.getFullYear();

        return `${day}.${month}.${year}`;
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        setIsDragOver(false);

        const file = event.dataTransfer.files[0];
        if (file && file.name.endsWith('.csv')) {
            setSelectedFile(file);
            setError('');
        } else {
            setSelectedFile(null);
            setError(`Error: '${file.name}' is not a supported file format. Please choose a CSV file and try again.`);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = (): void => {
        setIsDragOver(false);
    };

    if (loading) {
        return (
            <Paper style={{ padding: '25px', textAlign: 'center' }}>
                <CircularProgress />
            </Paper>
        );
    }

    return (
        <div style={{ padding: '25px' }}>
            <Paper>
                {configuration && (
                    <div style={{ padding: '25px' }}>
                        <Typography variant="h4">{configuration.details.displayName?.value || 'N/A'}</Typography>
                        <Typography variant="h6" style={{ marginBottom: '10px' }}>
                            Description: {configuration.details.description?.value || 'N/A'}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <Box
                                    border={1}
                                    padding={2}
                                    borderRadius={4}
                                    borderColor="grey.400"
                                    sx={{ height: '100%' }}
                                >
                                    <div>
                                        <Typography variant="h6">Configuration information:</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body2">
                                            Configuration Owner: {configuration.details.ownerEmail || 'N/A'}
                                        </Typography>
                                        <Typography variant="body2">
                                            Category: {configuration.details.category?.value || 'N/A'}
                                        </Typography>
                                        <Typography variant="body2">
                                            FileFormat: {configuration.details.fileFormat?.value || 'N/A'}
                                        </Typography>
                                        <Typography variant="body2">
                                            Sending: {configuration?.sendingDataToBQ?.type || 'N/A'}
                                        </Typography>
                                        <Typography variant="body2">
                                            Version: {configuration.details.version?.value || 'N/A'}
                                        </Typography>
                                    </div>
                                </Box>
                            </Grid>
                            <Grid item md={8}>
                                {configuration?.fileTemplate && (
                                    <Box
                                        border={1}
                                        padding={2}
                                        borderRadius={4}
                                        borderColor="grey.400"
                                        sx={{ height: '100%' }}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item md={7}>
                                                <Typography variant="h6">File template</Typography>
                                                <Typography variant="body2">
                                                    &apos;{configuration?.fileTemplate?.originalName}&apos; template
                                                </Typography>
                                                <Typography variant="body2">
                                                    File Version: {configuration.details.version?.value || 'N/A'}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Date: {convertTimestamp(configuration?.fileTemplate?.createdAt)}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    Please download the file template to review the file structure.
                                                    Kindly utilize this template to organize your files before
                                                    submission through our system.
                                                </Typography>
                                            </Grid>
                                            <Grid item md={5}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleDownloadFile}
                                                    >
                                                        Download File
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                        <div>
                            <Typography variant="h6" style={{ margin: '10px 0' }}>
                                Upload New File(s)
                            </Typography>
                            <div
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                style={{
                                    border: isDragOver ? '2px dashed #3f51b5' : '2px dashed #ccc',
                                    padding: '20px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                }}
                            >
                                <input
                                    accept=".csv"
                                    style={{ display: 'none' }}
                                    id="upload-file"
                                    type="file"
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="upload-file">
                                    <Button variant="contained" component="span">
                                        Select file(s)
                                    </Button>
                                </label>

                                <Typography variant="body2" color="textSecondary">
                                    {isDragOver ? 'Drop the file here...' : 'Or drag and drop a CSV file here'}
                                </Typography>
                            </div>

                            {selectedFile && (
                                <Box display="flex" alignItems="center" mt={2}>
                                    <Typography>{selectedFile.name}</Typography>
                                    <IconButton onClick={handleRemoveFile} style={{ padding: '3px 10px' }}>
                                        &times;
                                    </IconButton>
                                </Box>
                            )}

                            {error && (
                                <Typography color="error" variant="body2" mt={1}>
                                    {error}
                                </Typography>
                            )}

                            <Box mt={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleUploadFile}
                                    disabled={!selectedFile}
                                >
                                    Upload File
                                </Button>
                            </Box>
                        </div>
                    </div>
                )}
                <UploadHistoryList />
            </Paper>
        </div>
    );
};

export default EditConfigurationBusinessUser;
