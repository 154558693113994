import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './dropdown-menu.scoped.scss';
import NavbarItem from '@/components/navbar/components/navbar-item';

interface DropdownProps {
    title: string;
    items: { text: string; link: string }[];
}

const DropdownMenu: React.FC<DropdownProps> = ({ title, items }) => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const active = items.some((item) => location.pathname === item.link);
        setIsActive(active);

        if (active) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [location.pathname, items]);

    return (
        <div className={`dropdown ${isOpen ? 'open' : ''}`}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className={`dropdown-title ${isActive ? 'active' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                {title} {isOpen ? '-' : '+'}
            </div>
            {isOpen && (
                <div className="dropdown-items">
                    {items.map((item, index) => (
                        <NavbarItem key={index} text={item.text} link={item.link} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default DropdownMenu;
