import React, { useCallback, useEffect, useState } from 'react';
import {
    Paper,
    MenuItem,
    Grid,
    CircularProgress,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import { useConfigurationsApi } from '@/api-client/configurations-api';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import { ConfigurationResponse, CONFIGURATIONS_CATEGORY_STATUS, ConfigurationsCategories } from '@/types';
import RulesTable from '@/modules/configurations/configuration/rules-table';
import { AppDispatch } from '@/store';
import { useDispatch, useSelector } from 'react-redux';
import {
    getConfigurationsCategoriesFullListFromState,
    getConfigurationsCurrentConfiguration,
    getDefaultCurrentConfiguration,
    setCurrentConfiguration,
    setCurrentConfigurationBQColumns,
    setCurrentConfigurationBQDataset,
    setCurrentConfigurationBQTable,
    setCurrentConfigurationColumns,
    setCurrentConfigurationDetails,
    setCurrentConfigurationFileConfig,
    setCurrentConfigurationId,
    setCurrentConfigurationIsEditable,
    setCurrentConfigurationReferenceVersion,
    setCurrentConfigurationRulesCustom,
    setCurrentConfigurationRulesRegular,
    setCurrentConfigurationSendingDataToBQ,
    setCurrentConfigurationStatus,
    setCurrentConfigurationTeams,
    setCurrentConfigurationTemplateResource,
    setCurrentConfigurationUsers,
    setCurrentConfigurationVersionId,
} from '@/store/configurationsSlice';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import ColumnsTable from '@/modules/configurations/configuration/columns-table';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PermissionsTab from '@/modules/configurations/configuration/permissions-tab';
import FileUpload from '@/modules/configurations/configuration/file-upload-template';
import FileOptions from '@/modules/configurations/configuration/file-options';
import VersionHistory from '@/modules/configurations/configuration/version-history';
import Logs from '@/modules/configurations/configuration/logs';
import UploadHistoryList from '@/modules/configurations/configuration/upload-history-list';
import SendingDataToBQ from '@/modules/configurations/configuration/sending-data-to-bq';
import BigQueryOptions from '@/modules/configurations/configuration/big-query-options';
import CustomTextField from '@/components/custom-text-field';
import CustomSelect from '@/components/custom-select';

const EditConfiguration: React.FC = () => {
    const [isDraft, setIsDraft] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [isConfigurationsSet, setIsConfigurationsSet] = useState(false);
    const [configurationId, setConfigurationId] = useState(null);
    const {
        getConfigurationById,
        getConfigurationDraftById,
        updateDraftConfiguration,
        updateConfiguration,
        getConfigurationEditableById,
        postDraftConfigurationActivate,
        postConfigurationVersionActivate,
        createConfiguration,
    } = useConfigurationsApi();
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const dispatch: AppDispatch = useDispatch();
    const configuration = useSelector(getConfigurationsCurrentConfiguration);
    const configurationsCategoriesList = useSelector(getConfigurationsCategoriesFullListFromState);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState(!isDraft ? '1' : '2');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [statuses, setStatuses] = useState({
        technicalName: null,
        displayName: null,
        description: null,
        version: null,
        versionDescription: null,
        category: null,
        fileFormat: null,
    });

    useEffect(() => {
        setStatuses({
            technicalName: determineStatus(configuration.details.technicalName),
            displayName: determineStatus(configuration.details.displayName),
            description: determineStatus(configuration.details.description),
            version: determineStatus(configuration.details.version),
            versionDescription: determineStatus(configuration.details.versionDescription),
            category: determineStatus(configuration.details.category),
            fileFormat: determineStatus(configuration.details.fileFormat),
        });
    }, [configuration]);

    const determineStatus = (field: string | null): string | null => {
        if (field?.loading) {
            return 'loading';
        } else if (field?.success) {
            return 'success';
        } else if (field?.error) {
            return 'error';
        }
        return null;
    };

    useEffect(() => {
        if (configuration?.id) {
            setConfigurationId(configuration?.id);
        }
    }, [configuration.id]);

    const handleYes = (): void => {
        setIsModalVisible(false);
        setValue('4');
    };

    const handleSkip = (): void => {
        if (isDraft) {
            handlePublishDraft();
        } else {
            handlePublishVersion();
        }
        setIsModalVisible(false);
    };

    const handlePublishDraft = async (): Promise<void> => {
        try {
            await postDraftConfigurationActivate(configurationId || id);
            console.log('Draft configuration published successfully');
            enqueueSnackbar('Draft configuration published successfully', {
                variant: 'success',
                preventDuplicate: true,
            });
            navigate('/configurations');
        } catch (error) {
            if (error?.details?.violations) {
                error.details.violations.violations.forEach((violation: unknown) => {
                    enqueueSnackbar(`${violation.propertyPath}: ${violation.title}`, {
                        variant: 'error',
                        preventDuplicate: true,
                    });
                });
            } else {
                enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            }
            console?.error('Publishing draft configuration failed', error);
        }
    };

    const handlePublishVersion = async (): Promise<void> => {
        try {
            await postConfigurationVersionActivate(configuration.versionId);
            console.log('Configuration version published successfully');
            enqueueSnackbar('Configuration version published successfully', {
                variant: 'success',
                preventDuplicate: true,
            });
            navigate('/configurations');
        } catch (error) {
            if (error?.details?.violations) {
                error.details.violations.violations.forEach((violation: unknown) => {
                    enqueueSnackbar(`${violation.propertyPath}: ${violation.title}`, {
                        variant: 'error',
                        preventDuplicate: true,
                    });
                });
            } else {
                enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            }
            console?.error('Publishing configuration version failed', error);
        }
    };

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string): void => {
        setValue(newValue);
        if (!isCreate && !isDraft && newValue === '2' && !configuration.isEditable) {
            fetchEditableConfiguration();
        }
    };

    const fetchEditableConfiguration = async (): Promise<void> => {
        try {
            setIsConfigurationsSet(false);
            setLoading(true);
            const configuration = await getConfigurationEditableById(configurationId || id);
            transformToConfiguration(configuration);
            setLoading(false);
            setIsConfigurationsSet(true);
        } catch (error) {
            setLoading(false);
        }
    };

    const transformDraftToConfiguration = (draft: unknown): ConfigurationResponse => {
        const safeValue = (value: unknown): unknown => value ?? '';

        const draftConfigurationDetails = {
            technicalName: {
                value: safeValue(draft?.details?.technicalName),
                loading: false,
                success: false,
                error: false,
            },
            displayName: { value: safeValue(draft?.name), loading: false, success: false, error: false },
            category: { value: safeValue(draft?.category?.id), loading: false, success: false, error: false },
            description: {
                value: safeValue(draft?.details?.description),
                loading: false,
                success: false,
                error: false,
            },
            fileFormat: { value: safeValue(draft?.details?.fileType), loading: false, success: false, error: false },
            version: { value: safeValue(draft?.version), loading: false, success: false, error: false },
            versionComment: {
                value: safeValue(draft?.versionDescription),
                loading: false,
                success: false,
                error: false,
            },
        };
        const draftConfigurationFileConfig = {
            enclosure: {
                value: safeValue(draft?.fileOptions?.enclosure),
                loading: false,
                success: false,
                error: false,
            },
            encoding: { value: safeValue(draft?.fileOptions?.encoding), loading: false, success: false, error: false },
            escape: { value: safeValue(draft?.fileOptions?.escape), loading: false, success: false, error: false },
            delimiter: {
                value: safeValue(draft?.fileOptions?.delimiter),
                loading: false,
                success: false,
                error: false,
            },
            skipFirstLine: {
                value: safeValue(draft?.fileOptions?.skipFirstLine),
                loading: false,
                success: false,
                error: false,
            },
            skipEmptyLines: {
                value: safeValue(draft?.fileOptions?.skipEmptyLines),
                loading: false,
                success: false,
                error: false,
            },
        };
        const draftConfigurationRulesRegular = [...(draft?.schema?.rules ?? [])].filter(
            (rule) => rule.group === 'regular',
        );
        const draftConfigurationRulesCustom = [...(draft?.schema?.rules ?? [])].filter(
            (rule) => rule.group === 'custom',
        );
        const draftConfigurationColumns = [...(draft?.schema?.columns ?? [])];

        draftConfigurationColumns.forEach((column) => {
            column.ruleIds = column.rules?.map((rule) => rule.id) ?? [];
            delete column.rules;
        });

        const draftConfigurationUsers = draft?.permissions?.users?.map((user) => user.id) ?? [];
        const draftConfigurationTeams = draft?.permissions?.teams?.map((team) => team.id) ?? [];
        const draftConfigurationSendingOptions = draft?.sendingOptions ?? null;
        const draftConfigurationBQColumns = draft?.bigQueryMappingResource?.properties ?? [];

        dispatch(
            setCurrentConfigurationTemplateResource({
                templateExtension: safeValue(draft?.templateResource?.templateExtension),
                templateUuid: safeValue(draft?.templateResource?.templateUuid),
            }),
        );
        dispatch(setCurrentConfigurationId(safeValue(draft?.id)));
        dispatch(setCurrentConfigurationDetails(draftConfigurationDetails));
        dispatch(setCurrentConfigurationFileConfig(draftConfigurationFileConfig));
        dispatch(setCurrentConfigurationRulesRegular(draftConfigurationRulesRegular));
        dispatch(setCurrentConfigurationRulesCustom(draftConfigurationRulesCustom));
        dispatch(setCurrentConfigurationBQColumns(draftConfigurationBQColumns));
        dispatch(setCurrentConfigurationBQTable(safeValue(draft?.bigQueryMappingResource?.table)));
        dispatch(setCurrentConfigurationBQDataset(safeValue(draft?.bigQueryMappingResource?.dataset)));
        dispatch(setCurrentConfigurationColumns(draftConfigurationColumns));
        dispatch(setCurrentConfigurationUsers(draftConfigurationUsers));
        dispatch(setCurrentConfigurationTeams(draftConfigurationTeams));
        dispatch(setCurrentConfigurationSendingDataToBQ(draftConfigurationSendingOptions));
    };

    const transformToConfiguration = (configuration: unknown): ConfigurationResponse => {
        const safeValue = (value: unknown): unknown => value ?? '';

        const configurationDetails = {
            technicalName: {
                value: safeValue(configuration?.versionResources?.detailsResource?.technicalName),
                loading: false,
                success: false,
                error: false,
            },
            displayName: {
                value: safeValue(configuration?.configurationResource?.displayName),
                loading: false,
                success: false,
                error: false,
            },
            category: {
                value: safeValue(configuration?.configurationResource?.categoryResource?.id),
                loading: false,
                success: false,
                error: false,
            },
            description: {
                value: safeValue(configuration?.versionResources?.detailsResource?.description),
                loading: false,
                success: false,
                error: false,
            },
            fileFormat: {
                value: safeValue(configuration?.versionResources?.detailsResource?.fileType),
                loading: false,
                success: false,
                error: false,
            },
            version: {
                value: safeValue(configuration?.versionResources?.version),
                loading: false,
                success: false,
                error: false,
            },
            versionComment: {
                value: safeValue(configuration?.versionResources?.description),
                loading: false,
                success: false,
                error: false,
            },
        };
        const configurationFileConfig = {
            enclosure: {
                value: safeValue(configuration?.versionResources?.fileOptionsResource?.enclosure),
                loading: false,
                success: false,
                error: false,
            },
            encoding: {
                value: safeValue(configuration?.versionResources?.fileOptionsResource?.encoding),
                loading: false,
                success: false,
                error: false,
            },
            escape: {
                value: safeValue(configuration?.versionResources?.fileOptionsResource?.escape),
                loading: false,
                success: false,
                error: false,
            },
            delimiter: {
                value: safeValue(configuration?.versionResources?.fileOptionsResource?.delimiter),
                loading: false,
                success: false,
                error: false,
            },
            skipFirstLine: {
                value: safeValue(configuration?.versionResources?.fileOptionsResource?.skipFirstLine),
                loading: false,
                success: false,
                error: false,
            },
            skipEmptyLines: {
                value: safeValue(configuration?.versionResources?.fileOptionsResource?.skipEmptyLines),
                loading: false,
                success: false,
                error: false,
            },
        };
        const configurationRulesRegular = [...(configuration?.versionResources?.schemaResource?.rules ?? [])].filter(
            (rule) => rule.group === 'regular',
        );
        const configurationRulesCustom = [...(configuration?.versionResources?.schemaResource?.rules ?? [])].filter(
            (rule) => rule.group === 'custom',
        );
        const configurationColumns = [...(configuration?.versionResources?.schemaResource?.columns ?? [])];

        configurationColumns.forEach((column) => {
            column.ruleIds = column.rules?.map((rule) => rule.id) ?? [];
            delete column.rules;
        });

        const configurationUsers =
            configuration?.configurationResource?.permissionsResource?.users?.map((user) => user.id) ?? [];
        const configurationTeams =
            configuration?.configurationResource?.permissionsResource?.teams?.map((team) => team.id) ?? [];
        const configurationSendingOptions = configuration?.versionResources?.sendingOptionsResource ?? null;
        const configurationBQColumns = configuration?.versionResources?.bigQueryMappingResource?.properties ?? [];

        dispatch(
            setCurrentConfigurationTemplateResource({
                templateExtension: safeValue(configuration?.versionResources?.templateResource?.templateExtension),
                templateUuid: safeValue(configuration?.versionResources?.templateResource?.templateUuid),
            }),
        );
        dispatch(setCurrentConfigurationId(safeValue(configuration?.id)));
        dispatch(setCurrentConfigurationVersionId(safeValue(configuration?.versionResources?.id)));
        dispatch(setCurrentConfigurationReferenceVersion(safeValue(configuration?.versionResources?.referenceVersion)));
        dispatch(setCurrentConfigurationIsEditable(configuration?.isEditable ?? false));
        dispatch(setCurrentConfigurationStatus(safeValue(configuration?.versionResources?.status)));
        dispatch(setCurrentConfigurationDetails(configurationDetails));
        dispatch(setCurrentConfigurationFileConfig(configurationFileConfig));
        dispatch(setCurrentConfigurationRulesRegular(configurationRulesRegular));
        dispatch(setCurrentConfigurationRulesCustom(configurationRulesCustom));
        dispatch(setCurrentConfigurationBQColumns(configurationBQColumns));
        dispatch(
            setCurrentConfigurationBQTable(safeValue(configuration?.versionResources?.bigQueryMappingResource?.table)),
        );
        dispatch(
            setCurrentConfigurationBQDataset(
                safeValue(configuration?.versionResources?.bigQueryMappingResource?.dataset),
            ),
        );
        dispatch(setCurrentConfigurationColumns(configurationColumns));
        dispatch(setCurrentConfigurationUsers(configurationUsers));
        dispatch(setCurrentConfigurationTeams(configurationTeams));
        dispatch(setCurrentConfigurationSendingDataToBQ(configurationSendingOptions));
    };

    useEffect(() => {
        if (location.pathname.includes('/configurations/draft/')) {
            setIsDraft(true);
            setValue('2');
        } else if (location.pathname.includes('/configurations/create')) {
            setIsCreate(true);
            setValue('2');
            apiCall({}, configuration.details);
            setIsConfigurationsSet(true);
        }

        return () => {
            dispatch(setCurrentConfiguration(getDefaultCurrentConfiguration()));
        };
    }, []);

    const fetchConfiguration = async (): Promise<void> => {
        try {
            if (location.pathname.includes('/configurations/draft/')) {
                const draft = await getConfigurationDraftById(configurationId || id);
                transformDraftToConfiguration(draft);
            } else {
                const configuration = await getConfigurationById(configurationId || id);
                transformToConfiguration(configuration);
            }
            setLoading(false);
            setIsConfigurationsSet(true);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            fetchConfiguration();
            setConfigurationId(id);
        }
    }, [id, location.pathname]);

    const handleFieldChange = (e): void => {
        const { name, value } = e.target;
        dispatch(
            setCurrentConfigurationDetails({
                ...configuration.details,
                [name]: { value, loading: true, success: false, error: false },
            }),
        );
    };

    const rewriteEmptyStringsToNull = (obj: unknown): unknown => {
        if (obj === null || obj === undefined) {
            return obj;
        }

        if (typeof obj === 'string') {
            return obj === '' ? null : obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => rewriteEmptyStringsToNull(item));
        }

        if (typeof obj === 'object') {
            return Object.keys(obj).reduce(
                (acc, key) => {
                    acc[key] = rewriteEmptyStringsToNull(obj[key]);
                    return acc;
                },
                {} as { [key: string]: unknown },
            );
        }

        return obj;
    };

    const debouncedApiCall = useCallback(
        _.debounce(async (fields, configuration, isDraft) => {
            const updatedDetails = {};
            try {
                if (isDraft) {
                    await updateDraftConfiguration(configurationId || id, rewriteEmptyStringsToNull(fields));
                } else {
                    await updateConfiguration(configurationId || id, rewriteEmptyStringsToNull(fields));
                }
                Object.entries(configuration).forEach(([key, valueObj]) => {
                    if (valueObj?.loading) {
                        updatedDetails[key] = {
                            ...valueObj,
                            loading: false,
                            success: true,
                        };
                    } else {
                        updatedDetails[key] = valueObj;
                    }
                });

                dispatch(setCurrentConfigurationDetails(updatedDetails));
            } catch (error) {
                Object.entries(configuration).forEach(([key, valueObj]) => {
                    updatedDetails[key] = {
                        ...valueObj,
                        loading: false,
                        success: false,
                        error: false,
                    };
                });

                if (error) {
                    const violations = error.details?.violations?.violations || [error];
                    const propertyPathMapping = {
                        displayName: 'displayName',
                        technicalName: 'technicalName',
                        description: 'description',
                        version: 'version',
                        versionDescription: 'versionComment',
                        categoryId: 'category',
                        fileType: 'fileFormat',
                    };

                    violations.forEach((violation) => {
                        const propertyPath = violation.propertyPath || violation.details.reason?.split('.').pop();
                        const customPropertyPath = propertyPathMapping[propertyPath] || propertyPath;

                        enqueueSnackbar(`${customPropertyPath}: ${violation.title || violation.message}`, {
                            variant: 'error',
                            preventDuplicate: true,
                        });

                        if (updatedDetails[customPropertyPath]) {
                            updatedDetails[customPropertyPath] = {
                                ...updatedDetails[customPropertyPath],
                                error: true,
                            };
                        }
                    });
                }

                dispatch(setCurrentConfigurationDetails(updatedDetails));
            } finally {
                // console.log('finally');
            }
        }, 2000),
        [configurationId],
    );

    const apiCall = useCallback(
        _.debounce(async (fields, configuration) => {
            const updatedDetails = {};
            try {
                const created = await createConfiguration(rewriteEmptyStringsToNull(fields));
                dispatch(setCurrentConfigurationId(created.id));
                if (created.id) {
                    setIsConfigurationsSet(true);
                    setIsDraft(true);
                }
                Object.entries(configuration).forEach(([key, valueObj]) => {
                    console.log(valueObj);
                    if (valueObj?.loading) {
                        updatedDetails[key] = {
                            ...valueObj,
                            loading: false,
                            success: true,
                        };
                    } else {
                        updatedDetails[key] = valueObj;
                    }
                });

                dispatch(setCurrentConfigurationDetails(updatedDetails));
            } catch (error) {
                Object.entries(configuration).forEach(([key, valueObj]) => {
                    updatedDetails[key] = {
                        ...valueObj,
                        loading: false,
                        success: false,
                        error: false,
                    };
                });

                if (error) {
                    const violations = error.details?.violations?.violations || [error];
                    const propertyPathMapping = {
                        displayName: 'displayName',
                        technicalName: 'technicalName',
                        description: 'description',
                        version: 'version',
                        versionDescription: 'versionComment',
                        categoryId: 'category',
                        fileType: 'fileFormat',
                    };

                    violations.forEach((violation) => {
                        const propertyPath = violation.propertyPath || violation.details.reason?.split('.').pop();
                        const customPropertyPath = propertyPathMapping[propertyPath] || propertyPath;

                        enqueueSnackbar(`${customPropertyPath}: ${violation.title || violation.message}`, {
                            variant: 'error',
                            preventDuplicate: true,
                        });

                        if (updatedDetails[customPropertyPath]) {
                            updatedDetails[customPropertyPath] = {
                                ...updatedDetails[customPropertyPath],
                                error: true,
                            };
                        }
                    });
                }

                dispatch(setCurrentConfigurationDetails(updatedDetails));
            } finally {
                // console.log('finally');
            }
        }, 2000),
        [configurationId],
    );

    useEffect(() => {
        if (!isConfigurationsSet) {
            return;
        }
        if (!configurationId) {
            apiCall(
                {
                    displayName: configuration.details.displayName?.value,
                    technicalName: configuration.details.technicalName?.value,
                    description: configuration.details.description?.value,
                    version: configuration.details.version?.value,
                    versionDescription: configuration.details.versionComment?.value,
                    categoryId: configuration.details.category?.value,
                    fileType: configuration.details.fileFormat?.value,
                },
                configuration.details,
            );
        } else {
            debouncedApiCall(
                {
                    displayName: configuration.details.displayName?.value,
                    technicalName: configuration.details.technicalName?.value,
                    description: configuration.details.description?.value,
                    version: configuration.details.version?.value,
                    versionDescription: configuration.details.versionComment?.value,
                    categoryId: configuration.details.category?.value,
                    fileType: configuration.details.fileFormat?.value,
                },
                configuration.details,
                isDraft,
            );
        }
        return () => {
            debouncedApiCall.cancel();
            apiCall.cancel();
        };
    }, [
        configuration.details.displayName?.value,
        configuration.details.technicalName?.value,
        configuration.details.description?.value,
        configuration.details.version?.value,
        configuration.details.versionComment?.value,
        configuration.details.category?.value,
        configuration.details.fileFormat?.value,
    ]);

    if (loading) {
        return (
            <div style={{ padding: '25px' }}>
                <Paper style={{ display: 'flex', justifyContent: 'center', padding: '25px' }}>
                    <CircularProgress />
                </Paper>
            </div>
        );
    }

    const ConfigurationPanel: React.FC = () => {
        return (
            <>
                {configuration && (
                    <Grid container spacing={3}>
                        <Grid item xs={12} style={{ paddingBottom: '25px', paddingTop: 0, paddingLeft: '40px' }}>
                            {!isDraft ? <p style={{ fontSize: '20px' }}>Status: {configuration.status || ''}</p> : ''}
                        </Grid>
                        <Paper style={{ padding: '40px', width: '100%', marginBottom: '40px' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <h4>Configuration Details:</h4>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CustomTextField
                                                label="Technical Name"
                                                status={statuses.technicalName}
                                                value={configuration.details.technicalName?.value || ''}
                                                name="technicalName"
                                                onChange={handleFieldChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CustomTextField
                                                label="Display Name"
                                                status={statuses.displayName}
                                                value={configuration.details.displayName?.value || ''}
                                                name="displayName"
                                                onChange={handleFieldChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CustomSelect
                                                label="Category"
                                                status={statuses.category}
                                                value={configuration.details.category?.value || ''}
                                                onChange={handleFieldChange}
                                                name="category"
                                            >
                                                {configurationsCategoriesList.items
                                                    .filter(
                                                        (category: ConfigurationsCategories) =>
                                                            category.status === CONFIGURATIONS_CATEGORY_STATUS.ACTIVE,
                                                    )
                                                    .map((item: ConfigurationsCategories) => {
                                                        return (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </CustomSelect>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CustomTextField
                                                label="Description"
                                                status={statuses.description}
                                                value={configuration.details.description?.value || ''}
                                                name="description"
                                                onChange={handleFieldChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CustomSelect
                                                label="File Format"
                                                status={statuses.fileFormat}
                                                value={configuration.details.fileFormat?.value || ''}
                                                onChange={handleFieldChange}
                                                name="fileFormat"
                                            >
                                                <MenuItem value="">None</MenuItem>
                                                <MenuItem value="csv">CSV</MenuItem>
                                            </CustomSelect>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CustomTextField
                                                label="Version"
                                                status={statuses.version}
                                                value={configuration.details.version?.value || ''}
                                                name="version"
                                                onChange={handleFieldChange}
                                                disabled={isDraft}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CustomTextField
                                                label="Version Comment"
                                                status={statuses.versionComment}
                                                value={configuration.details.versionComment?.value || ''}
                                                name="versionComment"
                                                onChange={handleFieldChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} />
                                <Grid item xs={5}>
                                    <Grid
                                        container
                                        spacing={3}
                                        style={{ border: '1px solid #B1BACE', padding: '0 20px 20px 0' }}
                                    >
                                        <FileOptions configurationId={configurationId} isDraft={isDraft} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper style={{ padding: '40px', width: '100%', marginBottom: '40px' }}>
                            <Grid container spacing={3}>
                                <BigQueryOptions configurationId={configurationId} isDraft={isDraft} />
                            </Grid>
                        </Paper>
                        <Paper style={{ padding: '40px', width: '100%', marginBottom: '40px' }}>
                            <Grid container spacing={3}>
                                <SendingDataToBQ configurationId={configurationId} isDraft={isDraft} />
                            </Grid>
                        </Paper>
                        <Paper style={{ padding: '40px', width: '100%', marginBottom: '40px' }}>
                            <Grid container spacing={3}>
                                <ColumnsTable configurationId={configurationId} isDraft={isDraft} />
                            </Grid>
                        </Paper>
                        <Paper style={{ padding: '40px', width: '100%', marginBottom: '40px' }}>
                            <Grid container spacing={3}>
                                <RulesTable configurationId={configurationId} isDraft={isDraft} group="regular" />
                            </Grid>
                        </Paper>
                        <Paper style={{ padding: '40px', width: '100%', marginBottom: '40px' }}>
                            <Grid container spacing={3}>
                                <RulesTable configurationId={configurationId} isDraft={isDraft} group="custom" />
                            </Grid>
                        </Paper>
                        <div style={{ marginTop: '30px' }}>
                            <Link to={isDraft ? '/configurations/draft' : '/configurations'}>
                                <Button>Cancel</Button>
                            </Link>
                            <Button
                                onClick={() => {
                                    setIsModalVisible(true);
                                }}
                            >
                                Publish Configuration
                            </Button>
                        </div>
                        <Dialog
                            open={isModalVisible}
                            onClose={handleSkip}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {`You're going to activate a new version of this configuration. Would you like to
                                        re-upload the .csv template?`}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleSkip} color="primary">
                                    Skip
                                </Button>
                                <Button onClick={handleYes} color="primary">
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                )}
            </>
        );
    };

    return (
        <>
            <Dialog
                open={isModalVisible}
                onClose={handleSkip}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`You're going to activate a new version of this configuration. Would you like to
                                          re-upload the .csv template?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSkip} color="primary">
                        Skip
                    </Button>
                    <Button onClick={handleYes} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <TabContext value={value}>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        backgroundColor: '#fff',
                        padding: '0 25px',
                        position: 'sticky',
                        top: '0',
                        zIndex: '100',
                    }}
                >
                    <TabList onChange={handleChangeTab} aria-label="tabs">
                        {!isDraft && <Tab label="View" value="1" />}
                        <Tab label="Edit" value="2" />
                        <Tab label="Permissions" value="3" />
                        <Tab label="Template" value="4" />
                        <Tab label="Uploads History" value="7" />
                        {!isDraft && <Tab label="Version History" value="5" />}
                        {!isDraft && <Tab label="Logs" value="6" />}
                    </TabList>
                </Box>
                <div style={{ padding: '25px' }}>
                    {!isDraft && (
                        <TabPanel value="1">
                            <div style={{ pointerEvents: 'none' }}>
                                <ConfigurationPanel />
                            </div>
                        </TabPanel>
                    )}
                    <TabPanel value="2">
                        {configuration && (
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ paddingBottom: '25px', paddingTop: 0, paddingLeft: '40px' }}
                                >
                                    {configuration.details.displayName?.value ? (
                                        <p
                                            style={{
                                                fontSize: '48px',
                                                fontFamily: 'LVMHSans-SemiLight',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            Edit &apos;{configuration.details.displayName?.value}&apos;
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                    {!isDraft ? (
                                        <p style={{ fontSize: '20px' }}>Status: {configuration.status || ''}</p>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                                <Paper style={{ padding: '40px', width: '100%', marginBottom: '40px' }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <h4>Configuration Details:</h4>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CustomTextField
                                                        required
                                                        label="Technical Name"
                                                        status={statuses.technicalName}
                                                        value={configuration.details.technicalName?.value || ''}
                                                        name="technicalName"
                                                        onChange={handleFieldChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CustomTextField
                                                        required
                                                        label="Display Name"
                                                        status={statuses.displayName}
                                                        value={configuration.details.displayName?.value || ''}
                                                        name="displayName"
                                                        onChange={handleFieldChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CustomSelect
                                                        label="Category"
                                                        status={statuses.category}
                                                        value={configuration.details.category?.value || ''}
                                                        onChange={handleFieldChange}
                                                        name="category"
                                                    >
                                                        {configurationsCategoriesList.items
                                                            .filter(
                                                                (category: ConfigurationsCategories) =>
                                                                    category.status ===
                                                                    CONFIGURATIONS_CATEGORY_STATUS.ACTIVE,
                                                            )
                                                            .map((item: ConfigurationsCategories) => {
                                                                return (
                                                                    <MenuItem key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </CustomSelect>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CustomTextField
                                                        required
                                                        label="Description"
                                                        status={statuses.description}
                                                        value={configuration.details.description?.value || ''}
                                                        name="description"
                                                        onChange={handleFieldChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CustomSelect
                                                        required
                                                        label="File Format"
                                                        status={statuses.fileFormat}
                                                        value={configuration.details.fileFormat?.value || ''}
                                                        onChange={handleFieldChange}
                                                        name="fileFormat"
                                                    >
                                                        <MenuItem value="">None</MenuItem>
                                                        <MenuItem value="csv">CSV</MenuItem>
                                                    </CustomSelect>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CustomTextField
                                                        label="Version"
                                                        status={statuses.version}
                                                        value={configuration.details.version?.value || ''}
                                                        name="version"
                                                        onChange={handleFieldChange}
                                                        disabled={isDraft}
                                                        helperText={
                                                            configuration?.referenceVersion
                                                                ? `Current version: ${configuration?.referenceVersion}`
                                                                : ''
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CustomTextField
                                                        required
                                                        label="Version Comment"
                                                        status={statuses.versionComment}
                                                        value={configuration.details.versionComment?.value || ''}
                                                        name="versionComment"
                                                        onChange={handleFieldChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1} />
                                        <Grid item xs={5}>
                                            <Grid
                                                container
                                                spacing={3}
                                                style={{ border: '1px solid #B1BACE', padding: '0 20px 20px 0' }}
                                            >
                                                <FileOptions configurationId={configurationId} isDraft={isDraft} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Paper style={{ padding: '40px', width: '100%', marginBottom: '40px' }}>
                                    <Grid container spacing={3}>
                                        <BigQueryOptions configurationId={configurationId} isDraft={isDraft} />
                                    </Grid>
                                </Paper>
                                <Paper style={{ padding: '40px', width: '100%', marginBottom: '40px' }}>
                                    <Grid container spacing={3}>
                                        <SendingDataToBQ configurationId={configurationId} isDraft={isDraft} />
                                    </Grid>
                                </Paper>
                                <Paper style={{ padding: '40px', width: '100%', marginBottom: '40px' }}>
                                    <Grid container spacing={3}>
                                        <ColumnsTable configurationId={configurationId} isDraft={isDraft} />
                                    </Grid>
                                </Paper>
                                <Paper style={{ padding: '40px', width: '100%', marginBottom: '40px' }}>
                                    <Grid container spacing={3}>
                                        <RulesTable
                                            configurationId={configurationId}
                                            isDraft={isDraft}
                                            group="regular"
                                        />
                                    </Grid>
                                </Paper>
                                <Paper style={{ padding: '40px', width: '100%', marginBottom: '40px' }}>
                                    <Grid container spacing={3}>
                                        <RulesTable
                                            configurationId={configurationId}
                                            isDraft={isDraft}
                                            group="custom"
                                        />
                                    </Grid>
                                </Paper>
                                <div style={{ marginTop: '30px' }}>
                                    <Link to={isDraft ? '/configurations/draft' : '/configurations'}>
                                        <Button>Cancel</Button>
                                    </Link>
                                    <Button
                                        onClick={() => {
                                            setIsModalVisible(true);
                                        }}
                                    >
                                        Publish Configuration
                                    </Button>
                                </div>
                            </Grid>
                        )}
                    </TabPanel>
                    <TabPanel value="3">
                        <Grid container spacing={3}>
                            <Grid item xs={12} style={{ paddingBottom: '25px', paddingTop: 0, paddingLeft: '40px' }}>
                                {configuration.details.displayName?.value ? (
                                    <p
                                        style={{
                                            fontSize: '48px',
                                            fontFamily: 'LVMHSans-SemiLight',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        &apos;{configuration.details.displayName?.value}&apos; permission settings
                                    </p>
                                ) : (
                                    ''
                                )}
                            </Grid>
                            <Paper style={{ padding: '40px', width: '100%', marginBottom: '40px' }}>
                                <PermissionsTab configurationId={configurationId} isDraft={isDraft} />
                                <div style={{ marginTop: '30px' }}>
                                    <Button
                                        onClick={() => {
                                            setIsModalVisible(true);
                                        }}
                                    >
                                        Publish Configuration
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="4">
                        <Grid container spacing={3}>
                            <Grid item xs={12} style={{ paddingBottom: '25px', paddingTop: 0, paddingLeft: '40px' }}>
                                {configuration.details.displayName?.value ? (
                                    <p
                                        style={{
                                            fontSize: '48px',
                                            fontFamily: 'LVMHSans-SemiLight',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        &apos;{configuration.details.displayName?.value}&apos; template history
                                    </p>
                                ) : (
                                    ''
                                )}
                            </Grid>
                            <Paper style={{ padding: '40px', width: '100%', marginBottom: '40px' }}>
                                <FileUpload configurationId={configurationId} isDraft={isDraft} />
                            </Paper>
                        </Grid>
                    </TabPanel>
                    {!isDraft && (
                        <TabPanel value="5">
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ paddingBottom: '25px', paddingTop: 0, paddingLeft: '40px' }}
                                >
                                    {configuration.details.displayName?.value ? (
                                        <p
                                            style={{
                                                fontSize: '48px',
                                                fontFamily: 'LVMHSans-SemiLight',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            &apos;{configuration.details.displayName?.value}&apos; version history
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                                <VersionHistory
                                    configurationId={configurationId}
                                    transformToConfiguration={transformToConfiguration}
                                    setIsConfigurationsSet={setIsConfigurationsSet}
                                />
                            </Grid>
                        </TabPanel>
                    )}
                    {!isDraft && (
                        <TabPanel value="6">
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ paddingBottom: '25px', paddingTop: 0, paddingLeft: '40px' }}
                                >
                                    {configuration.details.displayName?.value ? (
                                        <p
                                            style={{
                                                fontSize: '48px',
                                                fontFamily: 'LVMHSans-SemiLight',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            &apos;{configuration.details.displayName?.value}&apos; logs
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                                <Logs />
                            </Grid>
                        </TabPanel>
                    )}
                    <TabPanel value="7">
                        <Grid container spacing={3}>
                            <Grid item xs={12} style={{ paddingBottom: '25px', paddingTop: 0, paddingLeft: '40px' }}>
                                {configuration.details.displayName?.value ? (
                                    <p
                                        style={{
                                            fontSize: '48px',
                                            fontFamily: 'LVMHSans-SemiLight',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        &apos;{configuration.details.displayName?.value}&apos; uploads history
                                    </p>
                                ) : (
                                    ''
                                )}
                            </Grid>
                            <UploadHistoryList />
                        </Grid>
                    </TabPanel>
                </div>
            </TabContext>
        </>
    );
};

export default EditConfiguration;
