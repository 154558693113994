import React, { useState, useEffect } from 'react';
import App from './app';
import { loadConfig } from '@/configService';
import { BrowserRouter } from 'react-router-dom';

const AppLoader: React.FC = () => {
    const [configLoaded, setConfigLoaded] = useState(false);

    useEffect(() => {
        loadConfig('/backend/api/v1/env')
            .then(() => {
                setConfigLoaded(true);
            })
            .catch((error) => {
                console.error('Error loading config:', error);
            });
    }, []);

    if (!configLoaded) {
        return <div>Loading configuration...</div>;
    }

    return (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    );
};

export default AppLoader;
