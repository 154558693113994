import { useApiClient } from '@/api-client/okta-api-client-controller';
import { Configuration } from '@/types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAdminConfigurationApi = () => {
    const { apiClient } = useApiClient();

    const getAdminConfigurationUploadsShow = async (configurationId: string): Promise<Configuration> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<Configuration>(
            `/api/v1/admin/configuration/${configurationId}/show`,
            { method: 'GET' },
            `getAdminConfigurationUploadsShow-${configurationId}`,
        );
    };

    return {
        getAdminConfigurationUploadsShow,
    };
};
